@-webkit-keyframes right {
  from {
    -webkit-transform: translateX(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    transform: translateX(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    -ms-transform: translateX(-4%);
  }
  to {
    -webkit-transform: translateX(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    transform: translateX(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    -ms-transform: translateX(4%);
  }
}

@keyframes right {
  from {
    -webkit-transform: translateX(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    transform: translateX(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    -ms-transform: translateX(-4%);
  }
  to {
    -webkit-transform: translateX(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    transform: translateX(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    -ms-transform: translateX(4%);
  }
}

@-webkit-keyframes left {
  from {
    -webkit-transform: translateX(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    transform: translateX(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    -ms-transform: translateX(4%);
  }
  to {
    -webkit-transform: translateX(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    transform: translateX(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    -ms-transform: translateX(-4%);
  }
}

@keyframes left {
  from {
    -webkit-transform: translateX(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    transform: translateX(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    -ms-transform: translateX(4%);
  }
  to {
    -webkit-transform: translateX(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    transform: translateX(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    -ms-transform: translateX(-4%);
  }
}

@-webkit-keyframes down {
  from {
    -webkit-transform: translateY(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    transform: translateY(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    -ms-transform: translateY(-4%);
  }
  to {
    -webkit-transform: translateY(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    transform: translateY(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    -ms-transform: translateY(4%);
  }
}

@keyframes down {
  from {
    -webkit-transform: translateY(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    transform: translateY(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    -ms-transform: translateY(-4%);
  }
  to {
    -webkit-transform: translateY(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    transform: translateY(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    -ms-transform: translateY(4%);
  }
}

@-webkit-keyframes up {
  from {
    -webkit-transform: translateY(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    transform: translateY(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    -ms-transform: translateY(4%);
  }
  to {
    -webkit-transform: translateY(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    transform: translateY(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    -ms-transform: translateY(-4%);
  }
}

@keyframes up {
  from {
    -webkit-transform: translateY(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    transform: translateY(calc((4vw + 4vh + 4%)/3)) rotate(0.01deg);
    -ms-transform: translateY(4%);
  }
  to {
    -webkit-transform: translateY(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    transform: translateY(calc((-4vw + -4vh + -4%)/3)) rotate(0.01deg);
    -ms-transform: translateY(-4%);
  }
}

@-webkit-keyframes out {
  from {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes out {
  from {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes in {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
}

@keyframes in {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
}

#slides {
  z-index: -1;
  position: fixed;
  width: 1px;
  height: 1px;
  left: -9999px;
}

#slideshow {
  z-index: -1;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#slideshow .slide, #slideshow span, #slideshow .static-content {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#slideshow .slide span {
  background-size: cover;
  background-position: center;
}

#slideshow .slide span.animate.right, #slideshow .slide span.animate.left {
  left: calc((-4vw + -4vh + -4%)/3);
  right: calc((-4vw + -4vh + -4%)/3);
}

#slideshow .slide span.animate.up, #slideshow .slide span.animate.down {
  top: calc((-4vw + -4vh + -4%)/3);
  bottom: calc((-4vw + -4vh + -4%)/3);
}

#slideshow span.animate {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

#slideshow span.animate.right {
  -webkit-animation-name: right;
  animation-name: right;
}

#slideshow span.animate.left {
  -webkit-animation-name: left;
  animation-name: left;
}

#slideshow span.animate.up {
  -webkit-animation-name: up;
  animation-name: up;
}

#slideshow span.animate.down {
  -webkit-animation-name: down;
  animation-name: down;
}

#slideshow span.animate.in {
  -webkit-animation-name: in;
  animation-name: in;
}

#slideshow span.animate.out {
  -webkit-animation-name: out;
  animation-name: out;
}

#slideshow span.animate.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
