@keyframes right {
  from {
    transform: translateX(calc(-1.33333vw - 1.33333vh - 1.33333%))rotate(.01deg);
    -ms-transform: translateX(-4%);
  }

  to {
    transform: translateX(calc(1.33333vw + 1.33333vh + 1.33333%))rotate(.01deg);
    -ms-transform: translateX(4%);
  }
}

@keyframes left {
  from {
    transform: translateX(calc(1.33333vw + 1.33333vh + 1.33333%))rotate(.01deg);
    -ms-transform: translateX(4%);
  }

  to {
    transform: translateX(calc(-1.33333vw - 1.33333vh - 1.33333%))rotate(.01deg);
    -ms-transform: translateX(-4%);
  }
}

@keyframes down {
  from {
    transform: translateY(calc(-1.33333vw - 1.33333vh - 1.33333%))rotate(.01deg);
    -ms-transform: translateY(-4%);
  }

  to {
    transform: translateY(calc(1.33333vw + 1.33333vh + 1.33333%))rotate(.01deg);
    -ms-transform: translateY(4%);
  }
}

@keyframes up {
  from {
    transform: translateY(calc(1.33333vw + 1.33333vh + 1.33333%))rotate(.01deg);
    -ms-transform: translateY(4%);
  }

  to {
    transform: translateY(calc(-1.33333vw - 1.33333vh - 1.33333%))rotate(.01deg);
    -ms-transform: translateY(-4%);
  }
}

@keyframes out {
  from {
    transform: scale(1.15);
  }

  to {
    transform: scale(1);
  }
}

@keyframes in {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.15);
  }
}

#slides {
  z-index: -1;
  width: 1px;
  height: 1px;
  position: fixed;
  left: -9999px;
}

#slideshow {
  z-index: -1;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

#slideshow .slide, #slideshow span, #slideshow .static-content {
  display: block;
  position: absolute;
  inset: 0;
}

#slideshow .slide span {
  background-position: center;
  background-size: cover;
}

#slideshow .slide span.animate.right, #slideshow .slide span.animate.left {
  left: calc(-1.33333vw - 1.33333vh - 1.33333%);
  right: calc(-1.33333vw - 1.33333vh - 1.33333%);
}

#slideshow .slide span.animate.up, #slideshow .slide span.animate.down {
  top: calc(-1.33333vw - 1.33333vh - 1.33333%);
  bottom: calc(-1.33333vw - 1.33333vh - 1.33333%);
}

#slideshow span.animate {
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

#slideshow span.animate.right {
  animation-name: right;
}

#slideshow span.animate.left {
  animation-name: left;
}

#slideshow span.animate.up {
  animation-name: up;
}

#slideshow span.animate.down {
  animation-name: down;
}

#slideshow span.animate.in {
  animation-name: in;
}

#slideshow span.animate.out {
  animation-name: out;
}

#slideshow span.animate.paused {
  animation-play-state: paused;
}

/*# sourceMappingURL=index.8e5422d3.css.map */
